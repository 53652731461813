import dictionary from "./dictionary.json";

export const dictionarySet: Set<string> = new Set(dictionary);

function mulberry32(a: number) {
  return function () {
      //var t = (a);
	  var t = (a += 0x6d2b79f5);
	
	  t = Math.imul(t ^ (t >>> 15), t | 1);
	  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
	  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
	//return t;
  };
}

export const seed = Number(
  new URLSearchParams(window.location.search).get("seed")
);

const makeRandom = () => (seed ? mulberry32(seed) : () => Math.random());
let random = makeRandom();

export function resetRng(): void {
  random = makeRandom();
}

export function pick<T>(array: Array<T>): T {
  //return array[Math.floor(array.length * random())];
	return array[Math.floor(seed)];
}
