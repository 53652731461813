import { useEffect, useState } from "react";
import { Row, RowState } from "./Row";
import dictionary from "./dictionary.json";
import { Clue, clue } from "./clue";
import { Keyboard } from "./Keyboard2";
import targetList from "./targets.json";
import { dictionarySet, pick, resetRng, seed } from "./util";
import * as React from 'react';
import { render } from 'react-dom';


enum ChallengState {
  Playing,
  Won,
  Lost,
}

interface ChallengProps {
  maxGuesses: number;
  hidden: boolean;
}

const targets = targetList
  .slice(0, targetList.indexOf("pupal") + 1); // Words no rarer than this one

function randomTarget(wordLength: number) {
  const eligible = targets.filter((word) => word.length === wordLength);
  return pick(eligible);
}

function Challeng(props: ChallengProps) {
  const [challengState, setChallengState] = useState(ChallengState.Playing);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState<string>("");
  const [wordLength, setWordLength] = useState(5);
  const [hint, setHint] = useState<string>(`Enter your word to challege someone!`);
  const [target, setTarget] = useState(() => {
    resetRng();
    return randomTarget(wordLength);
  });
  const [gameNumber, setGameNumber] = useState(1);
  const [counter, setCounter] = React.useState(1);
  var myWord:string="";
  var myNum:number;
  

  const startNextGame = () => {
    setTarget(randomTarget(wordLength));
    setGuesses([]);
    setCurrentGuess("");
    setHint("");
    setChallengState(ChallengState.Playing);
    setGameNumber((x) => x + 1);
	setCounter(1);
	
	
	
	
	
	
	
	
	//setTarget("bleed"); ////can set the word with setTarget.
	
	
  };
  React.useEffect(() => {
  if (challengState === ChallengState.Playing){
  	counter > 0 && setTimeout(() => setCounter(counter + 1), 1000);
  }
  }, [counter]);
  
  
 

  const onKey = (key: string) => {
   myWord = "";
    if (challengState !== ChallengState.Playing) {
      if (key === "Enter") {
        //startNextGame();
		console.log("enterHit");
		console.log("word: "+myWord);
		console.log("number: "+myNum);
		
		//window.location.href = `http://localhost:3000/?seed=${100}`;
		//window.location.reload();
		//"?seed=" + Math.floor(Math.random() * (5756))
      }
      return;
    }
	
  
    if (guesses.length === props.maxGuesses) return;
    if (/^[a-z]$/.test(key)) {
      setCurrentGuess((guess) => (guess + key).slice(0, wordLength));
      setHint("");
    } else if (key === "⌫") {
      setCurrentGuess((guess) => guess.slice(0, -1));
      setHint("");
    } else if (key === "Enter") {
      if (currentGuess.length !== wordLength) {
        setHint("Too short");
        return;
      }
      if (!dictionary.includes(currentGuess)) {
        setHint("Not a valid word");
        return;
      }
	  
      setGuesses((guesses) => guesses.concat([currentGuess]));
      setCurrentGuess((guess) => "");
	  
      if (currentGuess === target) {
	  
       // setHint(`You won in ${guesses.length+1} tries and ${counter} seconds! (Enter to play again)`);
		setHint(`${window.location.origin}/?seed=${targetList.indexOf(currentGuess)}`);
		navigator.clipboard.writeText(`${window.location.origin}/?seed=${targetList.indexOf(currentGuess)}`);
        setChallengState(ChallengState.Won);
		
      } else if (guesses.length + 1 === props.maxGuesses) {
	    
	  
       // setHint(
         // `Your challenge word ${currentGuess.toUpperCase()} Link: http://192.168.1.41:3000/?seed=${targetList.indexOf(currentGuess)}`
        //);
		setHint(
          `${window.location.origin}/?seed=${targetList.indexOf(currentGuess)}`
        );
		navigator.clipboard.writeText(`${window.location.origin}/?seed=${targetList.indexOf(currentGuess)}`);
		
		//setChallengeSeed(targetList.indexOf(currentGuess));
		var myWord = currentGuess;
		
		console.log("myWord: "+myWord); 
		myNum = (targetList.indexOf(myWord));
		console.log("number: "+myNum);
		
		//seed= 50
        setChallengState(ChallengState.Won);
      } else {
        setHint(`if you care ${target.toUpperCase()} is the answer and your ${currentGuess.toUpperCase()} is array # ${targetList.indexOf(currentGuess)}`);
		//setHint(`Your word ${currentGuess.toUpperCase()} is array # ${targetList.indexOf(currentGuess)}`);
      }
    }
  };


  useEffect(() => {
  
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, challengState]);

  let letterInfo = new Map<string, Clue>();
  const rowDivs = Array(props.maxGuesses)
    .fill(undefined)
    .map((_, i) => {
	
      const guess = [...guesses, currentGuess][i] ?? "";
      const cluedLetters = clue(guess, target);
      const lockedIn = i < guesses.length;
      
	  
	  
      return (
        <Row
          key={i}
          wordLength={wordLength}
          rowState={lockedIn ? RowState.Pending : RowState.Pending}
          cluedLetters={cluedLetters}
		  
        />
      );
    });
	
	
  return (
    <div className="Challeng" style={{ display: props.hidden ? "none" : "block" }}>
      <div className="challeg-options">
       
	   
	   
        
      </div>
      {rowDivs}
      <p>{hint || `\u00a0`}</p>
      <Keyboard letterInfo={letterInfo} onKey={onKey} />
      {seed ? (
        <div className="Game-seed-info">
          After 'Copy': now text or email someone your <br/>challenge word (by pasting)
        </div>
      ) : undefined}
	  
	  
	 
    </div>
  );
}

export default Challeng;
