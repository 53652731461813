import "./App.css";
import { seed } from "./util";
import Game from "./Game";
import Challeng from "./Challeng";
import { useState } from "react";
import { Row, RowState } from "./Row";
import { Clue } from "./clue";
import { Keyboard } from "./Keyboard";
//import React from "react";



//const maxGuesses = 8;
let maxGuesses:number = 7;



function About() {
//let maxGuess:number = 2;

//return maxGuesses;
  return (
    <div className="App-about">
      <p>
        <b>Word War</b> is a challenge a friend to guess your word game.
      </p>
      <p>
        You get {maxGuesses} tries to guess the word your friend created.
        <br />
        After each guess, you get Mastermind-style feedback:
      </p>
	  <p align="left"><b>Example:</b></p>
      <Row
        //rowState={RowState.Pending}
		rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Absent, letter: "a" },
          { clue: Clue.Elsewhere, letter: "l" },
          { clue: Clue.Absent, letter: "i" },
		  { clue: Clue.Correct, letter: "v" },
          { clue: Clue.Correct, letter: "e" },
        ]}
      />
      <p>
        <b>A</b> and <b>I</b> aren't in the word at all.
        <br />
        <b>V</b> and <b>E</b> are correct and in the correct spot.
        <br />
        <b>L</b> occurs some place else in the word.
      </p>
      <p>
        Let's move the <b>L</b> in our next guess:
      </p>
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Absent, letter: "d" },
          { clue: Clue.Absent, letter: "e" },
          { clue: Clue.Correct, letter: "l" },
		  { clue: Clue.Correct, letter: "v" },
          { clue: Clue.Correct, letter: "e" },
        ]}
      />
      <p>Getting closer. The word ends in LVE</p>
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "s" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "l" },
		  { clue: Clue.Correct, letter: "v" },
          { clue: Clue.Correct, letter: "e" },
        ]}
      />
	  
      <p>That's it!</p>
	  <p>Now you are ready for the challenge
	  <br />Don't forget to create your own challenge word
	  <br />Random allows you to play a word that was generated by the game</p>
	  <p>-----scroll to the top and close to begin------</p>
	  
      
    </div>
  );
}

function Challenge() {
//setChallenge = useState(true);
 const [about, setAbout] = useState(false);
  const [challenge, setChallenge] = useState(false);
  var gamestuff = document.getElementsByClassName('Game');

for (var i = 0; i < gamestuff.length; i ++) {
   // gamestuff[i].style.display = 'none';
}
  //document.getElementsByClassName('Game-seed-info')[0].style.visibility = 'hidden';
	
  return (
    <div className="Challeng"  >
      <p>
        Enter a <i>word</i> to challenge your friend
      </p>
	  <p>
        Then hit 'Copy' Key
      </p>
	  
      
	  <Challeng maxGuesses={1} hidden={challenge} />
	  
	  
      
    </div>
  );
  
}

function App() {
  const [about, setAbout] = useState(false);
  const [challenge, setChallenge] = useState(false);
  
  
  
 
  
  //let maxGuess:number = 8;
  
  return (
    <div className="App-container">
      <h1>Word War</h1>
      <div style={{ position: "absolute", right: 5, top: 5 }}>
        <a href="#" onClick={() => setAbout((a) => !a)}>
          {about ? "Close" : "About"}
        </a>
      </div>
	  <div style={{ position: "absolute", left: 5, top: 5 }}>
	 
        <a href="#" onClick={() => setChallenge((a) => !a)}>
          {challenge ? "Close" : "Challenge" }
        </a>
      </div>
      <div style={{ position: "absolute", right: 5, top: 30 }}>
        <a
          href="#"
          onClick={() =>
            (document.location = seed
              ? "?seed=" + Math.floor(Math.random() * (5756))
              : "?seed=" + Math.floor(Math.random() * (5756)))
			  
                
          }
        >
          {seed ? "Random" : "Random"}
        </a>
      </div>
	  {challenge && <Challenge />}
	  <div hidden={challenge} />
      {about && <About />}
	  
	  <Game maxGuesses={maxGuesses} hidden={challenge} />
	  
      
	  
    </div>
  );
}

function AppBackup() {
  const [about, setAbout] = useState(false);
  return (
    <div className="App-container">
      <h1>Word War Enter Your Challenge work</h1>
      <div style={{ position: "absolute", right: 5, top: 5 }}>
        <a href="#" onClick={() => setAbout((a) => !a)}>
          {about ? "Close" : "About"}
        </a>
      </div>
      <div style={{ position: "absolute", left: 5, top: 5 }}>
        <a
          href="#"
          onClick={() =>
            (document.location = seed
              ? "?"
              : "?seed=" +
                new Date().toISOString().replace(/-/g, "").slice(0, 8))
          }
        >
          {seed ? "where" : "Other"}
        </a>
      </div>
      {about && <About />}
      <Game maxGuesses={maxGuesses} hidden={about} />
    </div>
  );
}

export default App;
